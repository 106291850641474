import { createAction, props } from '@ngrx/store';
import { InboundDocument } from '../models/inbound-document.model';
import { HttpErrorResponse } from '@angular/common/http';

export const showEmail = createAction(
  '[Email/API] Load And View Email',
  props<{ file: InboundDocument.Model }>()
);

export const showEmailSuccess = createAction(
  '[Email/API] Load Email Success',
  props<{ payload: InboundDocument.Email }>()
);

export const showEmailFailure = createAction(
  '[Email/API] Load Email Failure',
  props<{ error: HttpErrorResponse }>()
);

export const closeEmail = createAction('[Email] Close Email');
