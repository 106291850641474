import { InboundDocument } from '../models/inbound-document.model';
import { createReducer, on } from '@ngrx/store';
import * as InboundDocumentActions from '../actions/inbound-document.actions';

export const featureKey = 'inboundDocument';

export interface State {
  emailOpened: boolean;
  loadedEmail: InboundDocument.Email;
  openedEmailRowUuid: string;
}

export const initialState = {
  emailOpened: false,
  loadedEmail: null,
  openedEmailRowUuid: null,
};

export const reducer = createReducer(
  initialState,
  on(InboundDocumentActions.showEmail, (state, { file }) => {
    return {
      ...state,
      emailOpened: true,
      openedEmailRowUuid: file.uuid,
    };
  }),
  on(InboundDocumentActions.showEmailSuccess, (state, { payload }) => {
    return {
      ...state,
      loadedEmail: payload,
    };
  }),
  on(InboundDocumentActions.closeEmail, (state) => {
    return {
      ...state,
      emailOpened: false,
      openedEmailRowUuid: null,
      loadedEmail: null,
    };
  })
);

export const getLoadedEmail = (state: State) => state.loadedEmail;
export const getEmailOpened = (state: State) => state.emailOpened;
export const getOpenedEmailRowUuid = (state: State) => state.openedEmailRowUuid;
